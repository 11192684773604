// Include the fonts
@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/fonts/frutiger-next-light.woff) format('opentype');
	font-weight: 300;
}
@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/fonts/frutiger-next-regular.woff) format('opentype');
	font-weight: 400;
}
@font-face {
	font-family: 'Frutiger Next';
	src: url(assets/fonts/frutiger-next-bold.woff) format('opentype');
	font-weight: 700;
}
@font-face {
	font-family: 'SF Pro Rounded semi-bold';
	src: url(assets/fonts/SF-Pro-Rounded-Semibold.ttf) format('truetype');
}
@font-face {
	font-family: 'Apple Monochrome Emoji Ind';
	src: url(assets/fonts/SFIndicesAMEmoji_regular.woff2) format('opentype');
}

:root {
	--zui-font-family: -apple-system, 'Frutiger Next', 'Helvetica Neue', 'Helvetica', 'Roboto',
		'Arial', sans-serif;
	--app-max-width: 1200px;
}

body {
	margin: 0;
	height: 100vh;
	width: 100vw;
	font-family: var(--zui-font-family) !important;
	font: var(--zui-typography-body);
	font-synthesis: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.pointer {
	cursor: pointer !important;
}

.forbidden {
	cursor: not-allowed !important;
}

// General
.vis-overlay-blur {
	top: calc(var(--zui-gu) * -1);
	bottom: calc(var(--zui-gu) * -1);
	left: calc(var(--zui-gu) * -1);
	right: calc(var(--zui-gu) * -1);
	position: absolute;
	z-index: 106;

	@supports (backdrop-filter: blur(4px)) {
		backdrop-filter: blur(4px);
	}
	@supports not (backdrop-filter: blur(4px)) {
		&::before {
			content: '';
			display: block;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			position: absolute;
			background-color: var(--zui-color-gs-10);
			opacity: 0.8;
		}
	}

	> div {
		display: flex;
		align-items: center;
		justify-content: center;
		max-height: 300px;
		height: 100%;
		width: 100%;

		> * {
			max-width: 300px;
		}
	}
}

// Vis item
.item-content {
	> :not(zui-interactive-icon):not(zui-button) {
		width: 100%;
	}

	> :first-child:not(zui-select):not(zui-textfield) {
		white-space: normal;
		margin-top: 0;
		margin-bottom: calc(var(--zui-gu) * 0.5);
	}

	> zui-select,
	> zui-textfield {
		margin-top: calc(var(--zui-gu) * -1);
	}
}

// Badge system
[badge] {
	position: relative;

	&:after {
		content: attr(badge);
		font-size: 11px;
		font-weight: bold;
		line-height: 1.4;
		background-color: var(--zui-color-info);
		position: absolute;
		border-radius: 40%;
		text-align: center;
		min-width: 10px;
		color: var(--zui-color-gs-10);
		padding: 0px 3px;
		top: 6px;
		right: 6px;
	}
}

[badge='0']:after,
[badge='']:after {
	display: none;
}

[badgecolor='error']:after,
[badgecolor='0']:after {
	background-color: var(--zui-color-error);
}

[badgecolor='warning']:after,
[badgecolor='1']:after {
	background-color: var(--zui-color-warning);
}

[badgecolor='success']:after,
[badgecolor='2']:after {
	background-color: var(--zui-color-success);
}

[badgecolor='info']:after,
[badgecolor='3']:after {
	background-color: var(--zui-color-info);
}

[badgecolor='grey']:after,
[badgecolor='4']:after {
	background-color: var(--zui-color-help);
}

h2 {
	margin-top: calc(var(--zui-gu) * 3) !important;
	margin-bottom: calc(var(--zui-gu) * 3) !important;
	height: calc(var(--zui-gu) * 3.5);
	color: var(--zui-color-text-header, --zui-color-gs-120);
}

a:not(.descrete) {
	color: var(--zui-color-sc-b-110);

	&:not(:hover) {
		text-decoration: none;
	}

	&:hover {
		color: var(--zui-color-info);
	}
}

a.descrete {
	text-decoration: none;
	color: var(--zui-color-text-header, --zui-color-gs-120);
}

// Styling for tables
.table-container {
	padding-bottom: calc(var(--zui-gu) * 0.5);
	min-height: 130px;

	tr.changed,
	html[theme='dark'] tr.changed {
		td:first-of-type {
			border-left-style: solid;
			border-left-width: calc(var(--zui-gu) * 0.25);
			border-left-color: var(--zui-color-info);
		}
	}

	tr.mat-row,
	tr.mat-footer-row {
		height: 26px;
	}

	th {
		font-weight: bold;

		&.mat-header-cell {
			border-bottom-width: 2px;
		}
	}

	th,
	td {
		&[no-bottom-line] {
			border-bottom-width: 0;
		}
	}

	td.no-wrap,
	&.no-wrap tbody td {
		white-space: pre;
	}

	&:not([dynamicheight]),
	&[dynamicheight='false'] {
		overflow: auto;
		height: 500px;

		&[reducedheight] {
			height: 340px;
		}
	}

	&[nodata='true'] {
		height: 100px;
		text-align: center;

		&::after {
			content: 'No orders yet ;-(';
		}
	}

	[vertical-line]:not([vertical-line='false']) {
		border-right-color: var(--zui-color-interactive-element-bg-default-hovered);
		border-right-width: calc(var(--zui-gu) * 0.125);
		border-right-style: solid;
	}

	[top-header] {
		text-align: center;
		border-bottom: none;
	}

	zui-menu-divider {
		--zui-menu-divider-height: auto;
	}
}

.fade-in {
	opacity: 0;
	animation: fade-in 0.3s ease-out forwards;
}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

img,
canvas {
	border: 1px dashed var(--zui-color-help);
	border-radius: var(--zui-gu);
}

.custom-label {
	color: var(--zui-color-text-default);
	font: var(--zui-typography-caption);
	font-size: 12px;
}

.no-wrap {
	white-space: pre;
}

.underline {
	text-decoration: underline;
}

vis-footer {
	width: 100%;
	display: block;
	padding: calc(var(--zui-gu) * 10) 0;
}

router-outlet ~ * {
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}
