@use '~@angular/material' as mat;

@include mat.core();

$vis-mat-primary: (
	500: var(--zui-color-gs-120),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-accent: (
	500: var(--zui-color-info),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-warn: (
	500: var(--zui-color-error),
	contrast: (
		500: var(--zui-color-gs-10),
	),
);

$vis-mat-secondary: (
	500: var(--zui-color-help),
	contrast: (
		500: var(--zui-color-gs-120),
	),
);

$visionportal-primary: mat.define-palette($vis-mat-primary, 500);
$visionportal-accent: mat.define-palette($vis-mat-accent, 500);
$visionportal-warn: mat.define-palette($vis-mat-warn, 500);
$visionportal-secondary: mat.define-palette($vis-mat-secondary, 500);
$visionportal-theme: mat.define-light-theme(
	(
		color: (
			primary: $visionportal-primary,
			accent: $visionportal-accent,
			warn: $visionportal-warn,
		),
	)
);

@include mat.all-component-themes($visionportal-theme);

$custom-typography: mat.define-typography-config(
	$font-family: '-apple-system, Frutiger Next, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif',
);

@include mat.core($custom-typography);

/*
* Mat Icon font
*/
$MaterialIcons_FontPath: '~material-icons-font/fonts';
@import '~material-icons-font/sass/variables';
@import '~material-icons-font/sass/mixins';
@import '~material-icons-font/sass/main';
@import '~material-icons-font/sass/Regular';

/*
* Table
*/
.mat-table,
html[theme='dark'] .mat-table {
	background: inherit;
	font: var(--zui-typography-body);
	width: 100%;
}
tbody,
html[theme='dark'] tbody {
	tr td {
		transition: background 0.3s;
	}
	tr:hover td:not(.col-no-hover) {
		transition: none;
		background: var(--zui-color-third-hierarchy-bg-default-enabled);
	}
	tr.selected,
	tr.selected code,
	tr.selected pre {
		font-weight: bold;
		background: var(--zui-color-third-hierarchy-bg-default-enabled);
	}
	tr.changed td:first-of-type {
		border-left-style: solid;
		border-left-width: calc(var(--zui-gu) * 0.25);
		border-left-color: var(--zui-color-info);
	}
}
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
	padding: calc(var(--zui-gu) * 0.5) calc(var(--zui-gu) * 1.5) !important;
	font-size: 12px;
	vertical-align: bottom;
	border-bottom-color: var(--zui-color-interactive-element-bg-default-hovered) !important;
}
td.mat-cell {
	color: var(--zui-color-text-default) !important;
}
th.mat-header-cell,
html[theme='dark'] th.mat-header-cell {
	// background-color: var(--zui-color-third-hierarchy-bg-default-enabled);
	text-transform: uppercase;
	font: var(--zui-typography-label2);
	color: var(--zui-color-text-header);
}
tr.mat-header-row {
	height: calc(var(--zui-gu) * 3) !important;
}
.mat-sort-header-content {
	text-align: start !important;
}
// set second header-row's top to the height of the first header-row
// (when .tall-header, e.g. when containing zui-dividers) if sticky
.mat-header-row.tall-header + .mat-header-row th.mat-table-sticky {
	top: 40px !important;
}

/*
* Hide ripple effects
*/
.mat-radio-ripple,
.mat-slide-toggle-ripple,
.mat-checkbox-ripple {
	height: 0 !important;
	width: 0 !important;
}

/*
* Mat chip
*/
.mat-chip {
	font: var(--zui-typography-caption);
	background-color: var(--zui-color-interactive-element-bg-default-enabled) !important;
	border-radius: var(--zui-border-radius-default) !important;
	border-width: var(--zui-line-strength-thin) !important;
	border-color: var(--zui-color-interactive-element-thin-line-default-enabled) !important;
	border-style: solid !important;
	padding: var(--zui-gu) !important;
}
.mat-chip-trailing-icon {
	height: var(--zui-size-icon-small) !important;
	width: var(--zui-size-icon-small) !important;
}
.mat-chip-list-wrapper {
	margin: 0 !important;
}

/*
* Formfield
*/
.mat-form-field-outline-start,
.mat-form-field-outline-end,
.mat-form-field-outline-gap {
	border-width: var(--zui-line-strength-thin) !important;
}
.mat-form-field-outline-start {
	border-radius: var(--zui-border-radius-default) 0 0 var(--zui-border-radius-default) !important;
}
.mat-form-field-outline-end {
	border-radius: 0 var(--zui-border-radius-default) var(--zui-border-radius-default) 0 !important;
}
.mat-form-field-label {
	font: var(--zui-typography-body) !important;
}
.mat-input-element {
	color: var(--zui-color-text-header, --zui-color-gs-120) !important;
}

/*
* Dialogs
*/
mat-dialog-container,
.mat-dialog-container {
	padding: 0 !important;
	border-radius: var(--zui-gu) !important;
	max-height: 80vh !important;
}

::ng-deep .mat-dialog-container {
	overflow-x: hidden;
}

/*
* TODO: Workaround to hide BufferBar in MatProgessBar (Buffer is showing even if there is no Buffer set)
*/
.mat-progress-bar .mat-progress-bar-background {
	display: none;
}

/*
* TODO: Style Material Icon sizes from ZUi
*/
mat-icon {
	&[size='4xl'] {
		height: var(--zui-size-icon-4xl);
		width: var(--zui-size-icon-4xl);
	}

	&[size='3xl'] {
		height: var(--zui-size-icon-3xl);
		width: var(--zui-size-icon-3xl);
	}

	&[size='2xl'] {
		height: var(--zui-size-icon-2xl);
		width: var(--zui-size-icon-2xl);
	}

	&[size='xl'] {
		height: var(--zui-size-icon-xl);
		width: var(--zui-size-icon-xl);
	}

	&[size='l'] {
		height: var(--zui-size-icon-l);
		width: var(--zui-size-icon-l);
	}

	&[size='m'] {
		height: var(--zui-size-icon-m);
		width: var(--zui-size-icon-m);
	}

	&[size='s'] {
		height: var(--zui-size-icon-s);
		width: var(--zui-size-icon-s);
	}

	&[size='xs'] {
		height: var(--zui-size-icon-xs);
		width: var(--zui-size-icon-xs);
	}
}

.mat-table-sticky {
	z-index: 2 !important;
}

/*
* TODO: Remove this once the zui-menu is usable (attached to html-body)
*/
.mat-menu-zui-style {
	margin-left: 20px;
	margin-top: -20px;
	overflow: visible !important;
	background-color: transparent !important;
	box-shadow: none !important;

	.mat-menu-content:not(:empty) {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

/*
* Datepicker in zui textfield's slot="interactive-icon"
*/
zui-textfield mat-datepicker-toggle .mat-icon-button {
	width: 18px;
	height: 18px;
	line-height: 18px;

	svg {
		width: 18px;
		height: 18px;
	}
}

/*
* Remove material border radius
*/
.mat-select-panel {
	border-radius: 0 !important;
}

/*
* Adaption of ZUi styles
*/
.mat-select-panel {
	box-shadow: var(--zui-box-shadow-default);
}

.mat-select-disabled .mat-select-value,
.mat-form-field-appearance-legacy .mat-form-field-label {
	color: var(--zui-color-text-default);
}
